import React, { createContext, useState, ReactNode } from 'react'

interface ContextProps {
	barTitle: string
	setBarTitle: React.Dispatch<React.SetStateAction<string>>
}

export const TitleBarContext = createContext<Partial<ContextProps>>({})

export const TitleBarProvider = (props: { children: ReactNode }) => {
	const [barTitle, setBarTitle] = useState('')
	return (
		<TitleBarContext.Provider value={{ barTitle, setBarTitle }}>
			{props.children}
		</TitleBarContext.Provider>
	)
}
