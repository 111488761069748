import React, { createContext, useState, ReactNode, useEffect } from 'react'

// import { apiRequest } from '../helpers'
import { UserModel } from '../interfaces'

interface BatchModel {
	has_audits: boolean
	name: string
	days: string[]
}

interface ContextProps {
	user: UserModel | null
	setUser: React.Dispatch<React.SetStateAction<UserModel | null>>
	userLoaded: boolean
	setUserLoaded: React.Dispatch<React.SetStateAction<boolean>>
	appVersions: any | null
	setAppVersions: React.Dispatch<React.SetStateAction<any | null>>
	appVersionsLoaded: boolean
	setAppVersionsLoaded: React.Dispatch<React.SetStateAction<boolean>>
	userSignedIn: boolean
	setUserSignedIn: React.Dispatch<React.SetStateAction<boolean>>
	userModel: UserModel | undefined
	setUserModel: React.Dispatch<React.SetStateAction<UserModel | undefined>>
	userModelLoaded: boolean
	setUserModelLoaded: React.Dispatch<React.SetStateAction<boolean>>
	sideNavActive: boolean
	setSideNavActive: React.Dispatch<React.SetStateAction<boolean>>
	barTitle: string | null
	setBarTitle: React.Dispatch<React.SetStateAction<string | null>>
	salesMode: boolean
	setSalesMode: React.Dispatch<React.SetStateAction<boolean>>
	batches: BatchModel[]
	setBatches: React.Dispatch<React.SetStateAction<BatchModel[]>>
	currentBatch:
		| {
				has_audits: boolean
				name: string
				id: number
				days: string[]
		  }
		| undefined
	setCurrentBatch: React.Dispatch<
		React.SetStateAction<
			| {
					has_audits: boolean
					name: string
					id: number
					days: string[]
			  }
			| undefined
		>
	>
	newAuthTime: number | null
	setNewAuthTime: React.Dispatch<React.SetStateAction<number | null>>
}
export const AppContext = createContext<ContextProps>({
	user: null,
	setUser: () => {},
	userLoaded: false,
	setUserLoaded: () => {},
	userSignedIn: false,
	setUserSignedIn: () => {},
	userModel: undefined,
	setUserModel: () => {},
	userModelLoaded: false,
	setUserModelLoaded: () => {},
	sideNavActive: false,
	setSideNavActive: () => {},
	barTitle: null,
	setBarTitle: () => {},
	appVersions: null,
	setAppVersions: () => {},
	appVersionsLoaded: false,
	setAppVersionsLoaded: () => {},
	salesMode: false,
	setSalesMode: () => {},
	batches: [],
	setBatches: () => {},
	currentBatch: undefined,
	setCurrentBatch: () => {},
	newAuthTime: null,
	setNewAuthTime: () => {},
})

export const AppProvider = (props: { children: ReactNode }) => {
	const [user, setUser] = useState<UserModel | null>(null)
	const [userLoaded, setUserLoaded] = useState(false)
	const [userSignedIn, setUserSignedIn] = useState(false)
	const [userModel, setUserModel] = useState<UserModel | undefined>(undefined)
	const [userModelLoaded, setUserModelLoaded] = useState(false)
	const [sideNavActive, setSideNavActive] = useState(true)
	const [barTitle, setBarTitle] = useState<string | null>(null)
	const [appVersions, setAppVersions] = useState<any | null>(null)
	const [appVersionsLoaded, setAppVersionsLoaded] = useState(false)
	const [salesMode, setSalesMode] = useState(false)
	const [batches, setBatches] = useState<BatchModel[]>([])
	const [currentBatch, setCurrentBatch] = useState<
		| {
				has_audits: boolean
				name: string
				id: number
				days: string[]
		  }
		| undefined
	>(undefined)
	const [newAuthTime, setNewAuthTime] = useState<number | null>(null)

	useEffect(() => {
		const currentTime = new Date().getTime()
		const expiry =
			sessionStorage.getItem('user') !== null
				? JSON.parse(sessionStorage.getItem('user')!).Time * 1000
				: 0
		const longerThanDay = (current: number, old: number) => {
			// console.log('currentTime', currentTime / 1000)
			// console.log('old', old / 1000)
			// console.log('current - old', (current - old) / 1000)
			// console.log(expiry)
			// console.log(current - old > expiry)
			// console.log(sessionStorage.getItem('user'))
			// console.log(JSON.parse(sessionStorage.getItem('user')!))

			if (current - old > expiry) {
				return true
			}
			if (!isNaN(old) && old !== null) {
				setNewAuthTime(expiry - (current - old))
			}
			return false
		}
		const authWatcher = () => {
			if (
				longerThanDay(currentTime, parseInt(sessionStorage.getItem('timestamp')!)) === false
			) {
				setUser(JSON.parse(sessionStorage.getItem('user')!))
				setUserLoaded(true)
			} else {
				setUser(null)
				setUserLoaded(false)
			}
		}
		authWatcher()
		return () => {
			setUser(null)
			setUserLoaded(false)
		}
	}, [])

	useEffect(() => {
		const userWatcher = () => {
			if (user) {
				setUserModel(user)
				setUserLoaded(true)
				setUserModelLoaded(true)
				sessionStorage.setItem('user', JSON.stringify(user))
			}
		}
		userWatcher()
		return () => {
			setUserModel(undefined)
			setUserLoaded(false)
			setUserModelLoaded(false)
		}
	}, [user])

	return (
		<AppContext.Provider
			value={{
				user,
				setUser,
				userLoaded,
				setUserLoaded,
				userSignedIn,
				setUserSignedIn,
				userModel,
				setUserModel,
				userModelLoaded,
				setUserModelLoaded,
				sideNavActive,
				setSideNavActive,
				barTitle,
				setBarTitle,
				appVersions,
				setAppVersions,
				appVersionsLoaded,
				setAppVersionsLoaded,
				salesMode,
				setSalesMode,
				batches,
				setBatches,
				currentBatch,
				setCurrentBatch,
				newAuthTime,
				setNewAuthTime,
			}}
		>
			{props.children}
		</AppContext.Provider>
	)
}
