import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { TEAppWrapper } from 'react-tec'
import './index.css'
import './reactTEC.css'
import 'semantic-ui-css/semantic.min.css'

import settings from './config/settings'

import { AppProvider } from './contexts'

import { theme } from './theme'

import App from './App'

const element = (
	<TEAppWrapper theme={theme}>
		<AppProvider>
			<App />
		</AppProvider>
	</TEAppWrapper>
)

ReactDOM.render(element, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
